import { useTranslation, Trans } from 'react-i18next';

export default function Footer() {
	
	const { t, i18n } = useTranslation();	
	
	return(
		<footer>
			<div className="container">
				<p>© Max Naylor 2024</p>
				<ul className="footer-links">
					<li><a href="/privacy-policy/">{t('footer.privacyPolicy')}</a></li>
					<li><a href="https://icelandicgrammar.com/" target="_new">{t('footer.gramRef')}</a></li>
					<li><a href="https://www.youtube.com/@islenskukennarinn" target="_new">{t('footer.youTube')}</a></li>
					<li><a href="mailto:info@icelandicwithmax.com">{t('footer.contact')}</a></li>
				</ul>
			</div>
		</footer>
	)	
	
}