import { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { useAuthContext } from '../contexts';
import { BreadcrumbsContext } from '../contexts';
import { getToken, calculateTrialDays } from '../helpers';

import { Button } from '../components/atoms'

function TrialExpired() {
	
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	return(
		<div className="dialog-shader visible show-nav">
			<div className="dialog">
				<h2>{t('trial.expiredDialog.heading')}</h2>
				<p>{t('trial.expiredDialog.explanation')}</p>
				<Button
					fullWidth={true}
					icon="next"
					iconPlacement="after"
					label={t('trial.expiredDialog.buttonPurchaseCourse')}
					onClick={() => navigate('/purchase', { replace: true })}
				/>
			</div>
		</div>
	)
	
}

function DashboardContent({ data, mode }) {
	
	const { t } = useTranslation();
	
	return(
		<div className="container">
			<div className="prose">
				<h1>{t('dashboard.courses')}</h1>
			</div>
			<section className="courses">
				{data.map((thisCourse, i) =>
					<div className="course-card" key={i}>
						<div className="cover">
							{mode === 'trial' ? (
								<>
									{thisCourse.attributes.cover.data && (
										<img 
											src={thisCourse.attributes.cover.data.attributes.url}
											alt={thisCourse.attributes.cover.data.attributes.alternativeText}
										/>
									)}
								</>
							) : (
								<>
									{thisCourse.attributes.product.data.attributes.cover.data && (
										<img 
											src={thisCourse.attributes.product.data.attributes.cover.data.attributes.url}
											alt={thisCourse.attributes.product.data.attributes.cover.data.attributes.alternativeText}
										/>
									)}
								</>
							)}							
						</div>
						<div className="progress-wrapper">
							<div className="progress-bar"></div>
						</div>
						<div className="course-label">
							<h2>{mode === 'trial' ? thisCourse.attributes.name : thisCourse.attributes.product.data.attributes.name}</h2>
							<Link to={`/${mode === 'trial' ? thisCourse.attributes.slug : thisCourse.attributes.product.data.attributes.slug}/`}>
								<button className="primary">
									{t('dashboard.buttonContinue')}
									<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M2 14.5C1.71875 14.5 1.46875 14.4062 1.28125 14.2188C0.875 13.8438 0.875 13.1875 1.28125 12.8125L6.5625 7.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L8.6875 6.8125C9.09375 7.1875 9.09375 7.84375 8.6875 8.21875L2.6875 14.2188C2.5 14.4062 2.25 14.5 2 14.5Z" fill="white"/>
									</svg>
								</button>
							</Link>
						</div>
					</div>
				)}
			</section>
		</div>
	)
	
}

export default function Dashboard() {
  
	const authToken = getToken();
	const user = useAuthContext();
	const navigate = useNavigate();
	
	const { t, i18n } = useTranslation();
	const breadcrumbs = useContext(BreadcrumbsContext);
	
	var trialDays = null;
	
	if(user.user) {
		var trialDays = calculateTrialDays(user.user.trialExpiry);
	}
	
  const [dashboard, setDashboard] = useState({
		data: null,
		error: false
  });
	
	const [loading, setLoading] = useState(true);
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
	}, [dashboard]);
	
	if(user.user) {
		if(trialDays) {
			var endpoint = 'courses';
			var q = stringify({
				filters: {
					productID: {
						$eq: '0001'
					}
				},
				populate: 'cover'
			});
		} else {
			var endpoint = 'purchases';
			var q = stringify({
				filters: {
					user: {
						$eq: user.user.id
					}
				},
				populate: {
					product: {
						populate: '*'
					}
				}
			});
		}		
	}	
	
	useEffect(() => {	
		if(user.user) {			
			fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/${endpoint}?${q}`, {
				headers: {
					Authorization: `Bearer ${authToken}`
				}
			})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if(data.data.length>0) {
					console.log(data.data);
					setDashboard({
						data: data.data
					});
					setLoading(false);
					var newBreadcrumbs = [{
						path: '/dashboard',
						title: 'Icelandic with Max',
						mobileStyle: 'hidden'	
					}];
					breadcrumbs.setBreadcrumbs(newBreadcrumbs);
				} else {
					setDashboard({
						data: null,
						error: false
					});					
					setLoading(false);
				}
			})
			.catch((error) => {
				setDashboard({
					data: null,
					error: error
				});				
				setLoading(false);
			});
		}
	}, [user.user, q]);
  
	if(loading || user.loading) {	
	 	return(
			<div className="container">
				<div className="prose">
					<h1>{t('dashboard.courses')}</h1>
				</div>
				<section className="courses">
					<div className="course-card skeleton"></div>						
					<div className="course-card skeleton"></div>
				</section>
			</div>
	 	)
	} if(dashboard.error) {	
		return(
			<div className="container">
				<div className="prose">
					<h1>{t('dashboard.courses')}</h1>					
				</div>
				<div className="placard error">{t('dashboard.errorLoading')}</div>
			</div>
		)
	} else {
		if(trialDays) {			
			if(trialDays > 0) {
				if(dashboard.data) {
					return (
						<DashboardContent data={dashboard.data} mode="trial" />
					);
				}
			} else {
				return (
					<TrialExpired />
				)
			}			
		} else {
			if(dashboard.data) {
				return (
					<DashboardContent data={dashboard.data} />
				);
			}	else {
				return (
					<div className="container">
						<div className="prose">
							<h1>{t('dashboard.courses')}</h1>
						</div>
						<div className="placard info">
							<div className="content-wrapper">
								{t('dashboard.noPurchases')}
								<Link to="/purchase">									
									<button>{t('dashboard.buttonPurchaseCourse')}</button>
								</Link>
							</div>
						</div>
					</div>
				)				
			}
		}
	}
}
