import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getToken, removeToken } from '../helpers';
import { useAuthContext } from '../contexts';

import { Button } from '../components/atoms';

export function PasswordDialog({ 
		visible,
		setVisible
	}) {
		
	const authToken = getToken();
	const user = useAuthContext();
	
	const navigate = useNavigate();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	
	const [passwordChanged, setPasswordChanged] = useState(false);
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	
	function handleCloseDialog() {
		reset();
		setError(false);
		setLoading(false);
		setVisible(false);
	}
	
	async function handleChangePassword(formData) {
		setPasswordChanged(false);
		if(formData.newPassword !== formData.confirmNewPassword) {
			setError({ message: t('account.changePassword.errors.noMatch') });
		}
		if(!error) {			
			setLoading(true);
			try {
				const value = {
					identifier: user.user.email,
					password: formData.password,
				};
				const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(value),
				});	
				const data = await response.json();
				if (data?.error) {
					throw data?.error;
				} else {
					console.log(formData.newPassword);
					const changePasswordValue = {
						password: formData.newPassword
					}
					const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${authToken}`
						},
						body: JSON.stringify(changePasswordValue),
					});
					const responseData = await response.json();		
					setPasswordChanged(true);
					setTimeout(() => {
						handleCloseDialog();
					}, '2500');
				}
			} catch (error) {
				console.error(error);
				if(error.message === 'Invalid identifier or password') {
					setError({ message: t('account.changePassword.errors.incorrectPassword') });
				} else {
					setError(error);
				}			
			} finally {
				setLoading(false);
			}
		}		
	}
		
	return(
		<div className={`dialog-shader${visible === 'password' ? (' visible') : ('')}`}>
			<div className="dialog">
				<div className="rows">
					<div className="row">
						<div className="prose">						
							<h2>{t('account.changePassword.changePassword')}</h2>
						</div>
					</div>
					{passwordChanged && (
						<div className="placard success">
							{t('account.changePassword.passwordChanged')}
						</div>
					)}	
					{error && (
						<div className="placard error">						
							{error.message}
						</div>
					)}
					{errors.password?.type === 'required' && (
						<div className="placard error">{t('account.changePassword.errors.noPassword')}</div>
					)}
					<form onSubmit={handleSubmit(handleChangePassword)}>
						<div className="row">
							<div className="vertical-row-content">
								<label htmlFor="password"><strong>{t('account.changePassword.existingPassword')}</strong></label>
								<input
									aria-invalid={errors.password ? "true" : "false"}
									className="account-form"
									disabled={loading ? ('disabled') : ('')}
									id="password"
									name="password"
									onFocus={() => setError(null)}
									type="password"
									{...register('password', { required: true })} 
								/>
							</div>
						</div>
						<div className="row">
							<div className="vertical-row-content">
								<label htmlFor="password"><strong>{t('account.changePassword.newPassword')}</strong></label>
								<input
									aria-invalid={errors.newPassword ? "true" : "false"}
									autoComplete="new-password"
									className="account-form"
									disabled={loading ? ('disabled') : ('')}
									id="newPassword"
									name="newPassword"
									onFocus={() => setError(null)}
									type="password"
									{...register('newPassword', { required: true, minLength: 6 })} 
								/>
								<label htmlFor="password"><strong>{t('account.changePassword.confirmNewPassword')}</strong></label>
								<input
									aria-invalid={errors.confirmNewPassword ? "true" : "false"}
									autoComplete="new-password"
									className="account-form"
									disabled={loading ? ('disabled') : ('')}
									id="confirmNewPassword"
									name="confirmNewPassword"
									onFocus={() => setError(null)}
									type="password"
									{...register('confirmNewPassword', { required: true, minLength: 6 })} 
								/>
							</div>
						</div>
						<div className="row">			
							<Button 
								fullWidth={true}  
								label={t('account.changePassword.buttonChangePassword')}
								disabled={loading ? (true) : (false)}
							/>
						</div>
					</form>
					<div className="row">
						<div className="vertical-row-content">		
							<Button
								disabled={loading ? (true) : (false)}
								fullWidth={true}
								label={t('account.changePassword.buttonCancel')}
								onClick={handleCloseDialog}
								role="secondary"
							/>					
						</div>
					</div>
				</div>
			</div>
		</div>
	)
	
}	

export function DeleteAccountDialog({ 
		visible,
		setVisible
	}) {
		
	const authToken = getToken();
	const user = useAuthContext();
	
	const navigate = useNavigate();
	
	const { t } = useTranslation();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [step, setStep] = useState(1);
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	
	function handleCloseDialog() {
		reset();
		setError(false);
		setLoading(false);
		setVisible(false);
		setStep(1);
	}
	
	async function handleDeleteAccount(formData) {
		setLoading(true);
		try {
			const value = {
				identifier: user.user.email,
				password: formData.password,
			};
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(value),
			});	
			const data = await response.json();
			if (data?.error) {
				throw data?.error;
			} else {
				const deleteValue = {
					blocked: true
				}
				const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND}/deleteaccount?id=${user.user.id}`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
					},
				});
				const responseData = await response.json();		
				if(!responseData.error) {
					removeToken();
					document.body.classList.remove('no-scroll');
					navigate('/', { replace: true });
					
				} else {
					console.error(responseData.error);
					setError({ message: 'An error occurred deleting the account.' });
				}
			}
		} catch (error) {
			console.error(error);
			if(error.message === 'Invalid identifier or password') {
				setError({ message: t('account.deleteAccount.errors.incorrectPassword') });
			} else {
				setError(error);
			}			
		} finally {
			setLoading(false);
		}
	}
		
	return(
		<div className={`dialog-shader${visible === 'delete' ? (' visible') : ('')}`}>
			{step === 1 && (
				<div className="dialog">
					<div className="rows">
						<div className="row">
							<div className="prose">						
								<h2>{t('account.deleteAccount.deleteAccount')}</h2>
								<p><strong>{t('account.deleteAccount.areYouSure')}</strong></p>
								<p>{t('account.deleteAccount.deleteWarning')}</p>
							</div>
						</div>
						<div className="row">
							<div className="vertical-row-content">
								<Button 
									fullWidth={true}  
									icon="delete"
									label={t('account.deleteAccount.buttonContinueToDelete')}
									onClick={() => setStep(2)} 
									role="secondary" 
								/>
								<Button
									fullWidth={true}
									label={t('account.deleteAccount.buttonCancel')}
									onClick={handleCloseDialog} 
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			{step === 2 && (
				<div className="dialog">
					<div className="rows">
						<div className="row">
							<div className="prose">						
								<h2>{t('account.deleteAccount.deleteAccount')}</h2>
								<p>{t('account.deleteAccount.deleteConfirm')}</p>
							</div>
						</div>
						{error && (
							<div className="placard error">						
								{error.message}
							</div>
						)}
						{errors.password?.type === 'required' && (
							<div className="placard error">{t('account.deleteAccount.errors.noPassword')}</div>
						)}
						<div className="row">
							<form onSubmit={handleSubmit(handleDeleteAccount)}>
								<input
									type="password"
									className="account-form"
									onFocus={() => setError(null)}
									{...register('password', { required: true })} 
									aria-invalid={errors.password ? "true" : "false"}
								/>
								{loading ? (
									<Button 
										disabled={true}
										label={t('account.deleteAccount.verifyingPassword')}
									/>
								) : (
									<Button 
										fullWidth={true}  
										icon="delete"
										label={t('account.deleteAccount.buttonDelete')}
										onClick={() => setStep(2)} 
										role="secondary" 
									/>
								)}								
							</form>
						</div>
						<div className="row">
							<div className="vertical-row-content">
								{loading ? (										
									<Button
										disabled={true}
										fullWidth={true}
										label={t('account.deleteAccount.buttonCancel')}
									/>
								) : (
										
									<Button
										fullWidth={true}
										label={t('account.deleteAccount.buttonCancel')}
										onClick={handleCloseDialog} 
									/>
								)}	
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
	
}	