import { RichTextBlockRenderer } from '../components/renderers';

export default function Verb(data) {
	if(data.content) {
		return (
			<div className="block verb">
				<div className="content">	
					<table>
						<tbody>
							<tr>
								<td></td>
								<th className="infinitive" width="55%">{data.content.content[0].infinitive}</th>
							</tr>
							<tr>
								<th className="padded">ég</th>
								<td className="padded">{data.content.content[0].firstSing}</td>
							</tr>
							<tr>
								<th>þú</th>
								<td>{data.content.content[0].secondSing}</td>
							</tr>
							<tr>
								<th>hann/hún/hán/það</th>
								<td>{data.content.content[0].thirdSing}</td>
							</tr>
							<tr>
								<th className="padded">við</th>
								<td className="padded">{data.content.content[0].firstPlu}</td>
							</tr>
							<tr>
								<th>þið</th>
								<td>{data.content.content[0].secondPlu}</td>
							</tr>
							<tr>
								<th>þeir/þær/þau</th>
								<td>{data.content.content[0].thirdPlu}</td>
							</tr>
						</tbody>			
					</table>
				</div>
			</div>
		);
	}
}