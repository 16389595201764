import { useState, useRef } from 'react';

export function AudioPlayer({src, type}) {
	
	const audio = useRef();
	const [loading, setLoading] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [progressBarWidth, setProgressBarWidth] = useState(4);
	
	function handleLoadedMetadata() {
		setLoading(false);
		setDuration(audio.current.duration);
	}
	
	function handlePlayAudio() {
		if(isPlaying===false) {
			audio.current.play();
			setIsPlaying(true);
		} else {
			audio.current.pause();
			setIsPlaying(false);
		}		
	}
	
	function handleAudioEnded() {
		setIsPlaying(false);
		setProgressBarWidth("100%");
	}
	
	function handleTimeUpdate() {
		setCurrentTime(audio.current.currentTime);
		setProgressBarWidth((currentTime/duration)*100+"%");
	}
	
	function handleScrub(value) {
		audio.current.currentTime = value;
	}
	
	function formatTime(time) {
		if (time && !isNaN(time)) {
			const minutes = Math.floor(time / 60);
			const formatMinutes =
				minutes < 10 ? `0${minutes}` : `${minutes}`;
			const seconds = Math.floor(time % 60);
			const formatSeconds =
				seconds < 10 ? `0${seconds}` : `${seconds}`;
			return `${formatMinutes}:${formatSeconds}`;
		}
		return '00:00';
	}
		
	return(
		<div className="audio-player">		
			<audio src={src} ref={audio} onEnded={handleAudioEnded} onLoadedMetadata={handleLoadedMetadata} onTimeUpdate={handleTimeUpdate} />
			<button onClick={handlePlayAudio}>
				{isPlaying ? (
					<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.75 0C13.9688 0 15 1.03125 15 2.25V15.75C15 17.0156 13.9688 18 12.75 18L11.25 17.9531C9.98438 17.9531 9 16.9219 9 15.7031V2.20312C9 0.984375 9.98438 0 11.25 0H12.75ZM3.75 0C4.96875 0 6 1.03125 6 2.25V15.75C6 17.0156 4.96875 18 3.75 18H2.25C0.984375 18 0 17.0156 0 15.75V2.25C0 0.984375 0.984375 0 2.25 0H3.75Z" fill="white"/>
					</svg>
					
				) : (
					<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.9219 9.07812C17.5781 9.5 18 10.25 18 11C18 11.7969 17.5781 12.5469 16.9219 12.9219L3.42188 21.1719C2.71875 21.5938 1.82812 21.6406 1.125 21.2188C0.421875 20.8438 0 20.0938 0 19.25V2.75C0 1.95312 0.421875 1.20312 1.125 0.828125C1.82812 0.40625 2.71875 0.40625 3.42188 0.875L16.9219 9.07812Z" fill="white"/>
					</svg>
				)}
			</button>
			{loading ? (				
				<div>Loading…</div>
			) : (
				<div className="progress">
					<div className="time">{formatTime(currentTime)}</div>
					<input 
						type="range"
						min={0}
						max={duration}
						step={0.1}
						value={currentTime}
						className="progress-bar"
						onChange={(e) => {
							handleScrub(e.currentTarget.valueAsNumber);
						}}>
					</input>
					<div className="time">{formatTime(duration)}</div>
				</div>
			)}			
		</div>
	);
	
}