import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { getToken, removeToken } from '../helpers';
import { useAuthContext } from '../contexts';

import { Button } from '../components/atoms';
import { PasswordDialog, DeleteAccountDialog } from '../components/dialogs';

function formatDate(string, lang) {
	return new Date(string).toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric'});
}

export default function Account() {
	
	const authToken = getToken();
	const { setUser } = useAuthContext();
	
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const user = useAuthContext();
	
	const [purchases, setPurchases] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [userInfoUpdating, setUserInfoUpdating] = useState(false);
	const [userInfoUpdated, setUserInfoUpdated] = useState(false);
	
	const [dialogVisible, setDialogVisible] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	useEffect(() => {
		if(dialogVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dialogVisible]);
	
	async function handleUpdateUserDetails(formData) {
		setUserInfoUpdated(false);
		var dataChanged = false;
		if(formData.firstName !== user.user.firstName) {
			dataChanged = true;
		}
		if(formData.email !== user.user.email) {
			dataChanged = true;
		}
		if(formData.gender !== user.user.gender) {
			dataChanged = true;
		}
		if(dataChanged) {			
			setUserInfoUpdating(true);
			try {
				const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${authToken}`
					},
					body: JSON.stringify(formData),
				});
				const responseData = await response.json();		
				setUser(responseData);
				setUserInfoUpdated(true);
			} catch (error) {
				console.error(Error);
			} finally {
				setUserInfoUpdating(false);
			}
		}	
	}
	
	useEffect(() => {
		if(user.user) {
			var q = stringify({
				filters: {
					user: {
						email: {
							$eq: user.user.email
						}				
					}
				},
				populate: '*',
				sort: ['transactionDate:desc']
			});
			fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/purchases?${q}`, {
				headers: {
					'Authorization': `Bearer ${authToken}`
				}
			})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if(data.data.length>0) {
					setPurchases(data.data);
					setLoading(false);
				} else {
					setLoading(false);
					setError(true);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(true);
			});
		}		
	}, [user, authToken]);
	
	function handleLogout() {
		removeToken();
		navigate('/', { replace: true });
	}
	
	if(loading) {
		return (
			<>
				<div className="container account">
					<div className="blurb">
						<h1>{t('account.account')}</h1>
					</div>						
					<section className="card skeleton">
					</section>
					<section className="card skeleton">
					</section>
					<section className="card skeleton">
					</section>
					<section className="card skeleton">
					</section>
				</div>
			</>
		)		
	}
	
	if(user.user) {
		return(
			<>
				<div className="container account">
					<div className="blurb">
						<h1>{t('account.account')}</h1>
					</div>				
					<section className="card account-section">
						<h2>{t('account.personalDetails.personalDetails')}</h2>								
						{userInfoUpdated && (
							<div className="placard success">
								{t('account.personalDetails.saved')}
							</div>
						)}	
						{errors.firstName?.type === 'required' && (
							<div className="placard error">Please enter your first name 👤</div>
						)}
						{errors.email?.type === 'required' && (
							<div className="placard error">Please enter your email address ✉️</div>
						)}
						{errors.email?.type === 'pattern' && (
							<div className="placard error">Please enter a valid email address ✉️</div>
						)}
						<div className="centred-content">
							<form onSubmit={handleSubmit(handleUpdateUserDetails)}>
								<div className="rows">
									<div className="row">
										<label htmlFor="firstName" className="row-label"><strong>{t('account.personalDetails.firstName')}</strong></label>
										<input 
											type="text" 
											id="firstName" 
											name="firstName" 
											className="account-form" 
											defaultValue={user.user.firstName} 
											{...register('firstName', { required: true })}
											aria-invalid={errors.firstName ? "true" : "false"}
											onFocus={() => setUserInfoUpdated(false)}
										/>
									</div>
									<div className="row">
										<label htmlFor="email" className="row-label">{t('account.personalDetails.email')}</label>
										<input 
											type="email" 
											id="email" 
											name="email" 
											className="account-form" 
											defaultValue={user.user.email} 
											{...register('email', { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/ })} 
											aria-invalid={errors.email ? "true" : "false"}
											onFocus={() => setUserInfoUpdated(false)}
										/>
									</div>
									<div className="row">
										<label htmlFor="firstname" className="row-label">{t('account.personalDetails.gender.gender')}</label>
										<div className="vertical-row-content">
											<div className="radio-wrapper">
												<div className="radio-button-wrapper">
													<input 
														type="radio" 
														id="male" 
														name="gender" 
														value="m" 
														defaultChecked={user.user.gender === 'm'} 
														{...register('gender', { required: true })}												
													/>
													<label htmlFor="male">{t('account.personalDetails.gender.male')}</label>							
												</div>							
												<div className="radio-button-wrapper">
													<input 
														type="radio" 
														id="female" 
														name="gender" 
														value="f" 
														defaultChecked={user.user.gender === 'f'} 
														{...register('gender', { required: true })}
													/>
													<label htmlFor="female">{t('account.personalDetails.gender.female')}</label>
												</div>
												<div className="radio-button-wrapper">
													<input 
														type="radio" 
														id="nonbinary" 
														name="gender" 
														value="nb" 
														defaultChecked={user.user.gender === 'nb'} 
														{...register('gender', { required: true })}
													/>
													<label htmlFor="nonbinary">{t('account.personalDetails.gender.nonbinary')}</label>
												</div>
											</div>
											<div className="explanation">{t('account.personalDetails.gender.explanation')}</div>
										</div>
									</div>
									<div className="row centred">				
										{userInfoUpdating ? (											
											<Button	label={t('account.personalDetails.saving')} disabled={true} />
										) : (											
											<Button	label={t('account.personalDetails.saveChanges')} onClick={handleUpdateUserDetails} />
										)}											
									</div>
								</div>
							</form>
						</div>
					</section>			
					<section className="card account-section">
						<h2>{t('account.password.password')}</h2>
						<div className="centred-content padded">
							<div className="rows">
								<div className="row">
									<Button											
										fullWidth="true" 
										label={t('account.password.buttonChangePassword')} 
										onClick={() => setDialogVisible('password')}
									/>
								</div>
							</div>
						</div>
					</section>
					<section className="card account-section">
						<h2>{t('account.purchaseHistory.purchaseHistory')}</h2>
						{purchases ? (
							<table className="pretty full-width">
								<thead>
									<tr>
										<th>{t('account.purchaseHistory.course')}</th>
										<th>{t('account.purchaseHistory.purchaseDate')}</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									{purchases.map((thisPurchase, i) =>
										<tr key={i}>
											<td>{thisPurchase.attributes.product.data.attributes.name}</td>
											<td>{formatDate(thisPurchase.attributes.transactionDate, i18n.language)}</td>
											{thisPurchase.attributes.invoiceLink && (												
												<td className="align-right">
													<Button 
														role="link" 
														label={t('account.purchaseHistory.downloadInvoice')} 
														icon="download" 
														iconPlacement="after"
													/>
												</td>
											)}
										</tr>									
									)}								
								</tbody>
							</table>
						) : (
							<div className="loading">{t('account.purchaseHistory.errors.noPurchases')}</div>
						)}								
					</section>			
					<section className="card account-section">
						<h2>{t('account.accountManagement.accountManagement')}</h2>
						<div className="centred-content padded">
							<div className="rows">
								<div className="row">
									<Button	
										label={t('account.accountManagement.logOut')} 
										icon="logOut" 
										fullWidth="true" 
										onClick={handleLogout} 
									/>
								</div>
								<div className="row">								
									<div className="vertical-row-content">
										<Button	
											label={t('account.accountManagement.deleteAccount')} 
											role="secondary" 
											icon="delete" 
											fullWidth="true" 
											onClick={() => setDialogVisible('delete')}
										/>
										<div className="explanation">{t('account.accountManagement.explanation')}</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<PasswordDialog visible={dialogVisible} setVisible={setDialogVisible} />
				<DeleteAccountDialog visible={dialogVisible} setVisible={setDialogVisible} />
			</>
		)	
	}
		
}
