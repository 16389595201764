export const getToken = () => {
	return localStorage.getItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN);
};

export const setToken = (token) => {
	if (token) {
		localStorage.setItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN, token);
	}
};

export const removeToken = () => {
	localStorage.removeItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN);
};

export function calculateTrialDays(expiryDate) {	
	const today = new Date().getTime();
	expiryDate = Date.parse(expiryDate);			
	const difference = expiryDate - today;
	return  Math.round(difference / (1000 * 3600 * 24))
}