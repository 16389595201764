import { useState, useEffect } from 'react';
import { AuthContext } from './contexts';
import { getToken } from './helpers';

const AuthProvider = ({ children }) => {
	
	const [userData, setUserData] = useState();
	const [loading, setLoading] = useState(false);
	
	const authToken = getToken();
	
	const fetchLoggedInUser = async (token) => {
		setLoading(true);
		try {
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/me`, {
				headers: { Authorization: `${process.env.REACT_APP_STRAPI_BEARER} ${authToken}` },
			});
			const data = await response.json();	
			setUserData(data);
			localStorage.setItem('lang', data.lang);
			localStorage.setItem('appearance', data.appearance);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	
	const handleUser = (user) => {
		setUserData(user);
	};
	
	useEffect(() => {
		if(authToken) {
			fetchLoggedInUser(authToken);
		}
	}, [authToken]);
	
	return(
		<AuthContext.Provider
			value={{ user: userData, setUser: handleUser, loading }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider;