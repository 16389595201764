import { Fragment } from 'react';

import { useAuthContext } from '../contexts';

import { AudioButton } from './atoms';

function ReplaceHandlebars(string) {
	const user = useAuthContext();
	var string = string.replace('{{userFirstName}}', user.user.firstName);
	return string;
}

function LineBreakRenderer(textObject) {
	var textContent = [];
	var lineBreakSplit = textObject.split(/\n/);
	if(lineBreakSplit.length > 1) {
		lineBreakSplit.map(function(thisLine, i) {
			textContent.push(<>{thisLine}<br key={i} /></>)
		});
	}
	return(
		textContent
	)
}

function TextFormatter(textObject, key) {
	var textContent = [];
	if(textObject.textObject.bold && textObject.textObject.italic) { 
		textContent.push(<strong key={key}><em>{ReplaceHandlebars(textObject.textObject.text)}</em></strong>) 
	} else if(textObject.textObject.bold) { 
		textContent.push(<strong key={key}>{ReplaceHandlebars(textObject.textObject.text)}</strong>) 
	} else if(textObject.textObject.italic && textObject.textObject.underline) { 
		textContent.push(<em key={key}><u>{ReplaceHandlebars(textObject.textObject.text)}</u></em>) 
	} else if(textObject.textObject.italic) { 
		textContent.push(<em key={key}>{ReplaceHandlebars(textObject.textObject.text)}</em>) 
	} else if(textObject.textObject.underline) { 
		textContent.push(<u key={key}>{ReplaceHandlebars(textObject.textObject.text)}</u>) 
	} else {
		textContent.push(ReplaceHandlebars(textObject.textObject.text));
	}	
	return(
		textContent
	)
}

function TextRenderer(textArray) {
	var textContent = [];	
	{textArray.textArray.map(function(thisChild, k) {
		if(thisChild.type === "text") {
			textContent.push(<TextFormatter textObject={thisChild} key={k} />)
		} else if(thisChild.type === "link") {
			var linkContent = [];
			textContent.push(
				<a href={thisChild.url} key={k} target="_new">
					{thisChild.children.map(function(thisTextChild, j) {
						linkContent.push(<TextFormatter textObject={thisTextChild} key={j} />)
					})}
					{linkContent}
				</a>
			)
		}							
	})}
	return(	
		textContent
	)
}

function RichTextBlockRenderer({ content, audio, noptags }) {
	if(content) {
		var richTextContent = [];
		content.map(function(thisBlock, k) {		
			if(thisBlock.type === "paragraph") {
				if(noptags) {					
					richTextContent.push(
						<TextRenderer textArray={thisBlock.children} key={k} />
					)
				} else {					
					richTextContent.push(
						<p key={k}>
							<TextRenderer textArray={thisBlock.children} />
							{audio && (	
								<AudioButton src={`${audio.attributes.url}`} type={audio.attributes.mime} />
							)}
						</p>
					)
				}
			} else if(thisBlock.type === "heading") {
				switch(thisBlock.level) {
					case 2:
						richTextContent.push(
							<h2 key={k}>
								<TextRenderer textArray={thisBlock.children} />
							</h2>
						);
						break;
					case 3:
						richTextContent.push(
							<h3 key={k}>
								<TextRenderer textArray={thisBlock.children} />
							</h3>
						);
						break;
				}				
			} else if(thisBlock.type === "list") {
				if(thisBlock.format === "unordered") {
					var listItemContent = [];
					//console.log(thisBlock.children);
					richTextContent.push(
						<ul key={k}>
							{thisBlock.children.map(function(thisChild, j) {
								listItemContent.push(
									<li key={j}>
										<TextRenderer textArray={thisChild.children} />
									</li>
								)
							})}
							{listItemContent}
						</ul>
					)
				}
			}
		});
		return richTextContent;
	}	
}

function BlockRenderer(blocks) {
	var content = [];
	blocks.content.map(function(thisBlock, i) {
		if(thisBlock.__component === "lesson-page-blocks.paragraph") {
			content.push(
				<div className="prose" key={i}>
					<RichTextBlockRenderer content={thisBlock.content} />
				</div>
			)
		} else if(thisBlock.__component === "lesson-page-blocks.callout") {
			content.push(
				<div className={`callout ${thisBlock.type}`} key={i}>
					<RichTextBlockRenderer content={thisBlock.content} />
				</div>
			)
		} else if(thisBlock.__component === "lesson-page-blocks.vocab-block") {
			if(thisBlock.hero) {
				content.push(		
					<div className="block-wrapper" key={i}>
						{thisBlock.row.map((thisRow, i) =>											
							<div className={`block hero col-${thisBlock.columns}`} key={i}>			
								<RichTextBlockRenderer content={thisBlock.row[i].content} />
								{thisBlock.row[i].audio.data && (	
									<AudioButton src={`${thisBlock.row[i].audio.data.attributes.url}`} type={thisBlock.row[i].audio.data.attributes.mime} />
								)}
							</div>
						)}
					</div>
				)
			} else {
				content.push(		
					<div className="block vocab" key={i}>
						{thisBlock.image.data && (						
							<img 
								src={thisBlock.image.data.attributes.url}
								alt={thisBlock.image.data.attributes.alternativeText} 
								className={thisBlock.imageSize}
							/>
						)}
						<div className={`content${thisBlock.vAlign ? " "+thisBlock.vAlign : ""}`}>
							{[...Array(thisBlock.columns)].map((x, i) =>
								<div className="col" key={i}>
									{thisBlock.row.map((thisRow, j) =>		
										<Fragment key={j}>
											{thisRow.column === i+1 && (								
												<div className={`row${thisRow.gap ? " padded" : ""}${thisRow.isCentred ? " centred" : ""}${thisRow.isColumn ? " as-column" : ""}`}>															
													{((thisRow.column === i+1) && (thisRow.image.data)) && (						
														<img 
															src={thisRow.image.data.attributes.url}
															alt={thisRow.image.data.attributes.alternativeText} 
															className={thisRow.imageSize}
														/>
													)}	
													<div className="prose">
														<RichTextBlockRenderer content={thisBlock.row[j].content} audio={thisBlock.row[j].audio.data} />
													</div>
												</div>
											)}
										</Fragment>
									)}
								</div>
							)}
						</div>
					</div>
				)
			}
		} 
	});
	return content;
}

export { 
	TextFormatter,
	TextRenderer,
	RichTextBlockRenderer,
	BlockRenderer
}