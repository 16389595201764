import { useState } from 'react';

import { BreadcrumbsContext } from './contexts';
import AuthProvider from './authProvider';

import './styles/variables.css';
import './styles/styles.css';

import Main from './main';

export default function App() {
	
	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			path: '/dashboard',
			title: 'Icelandic with Max',
			mobileStyle: 'hidden'
		}
	]);
	
  return (
		<AuthProvider>
	  	<BreadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
				<Main />
			</BreadcrumbsContext.Provider>
		</AuthProvider>
  );
}