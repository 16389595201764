import { useState, useEffect } from 'react';
import { stringify } from 'qs';

import CourseCard from '../components/courseCard';

export default function PurchaseCourse() {
	
	const [loading, setLoading] = useState(true);
	const [courseData, setCourseData] = useState(false);
	const [squarePaymentLink, setSquarePaymentLink] = useState(null);
	const [paymentProcessing, setPaymentProcessing] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	
	var q = stringify({
		sort: ['slug:asc'],
		populate: ['features'],
		publicationState: 'preview',
	});
	
	useEffect(() => {
		console.log('Getting payment link');
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses?${q}`)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if(data.data.length>0) {
					setCourseData(data.data);
					var paymentLinkQ = stringify({
						productName: data.data[0].attributes.name,
						price: data.data[0].attributes.price,						
						email: 'maxnaylor@mac.com',
					});
					fetch(`${process.env.REACT_APP_NODE_BACKEND}/?${paymentLinkQ}`)
					.then((res) => {
						return res.json();
					})
					.then((data) => {			
						if(data) {
							setSquarePaymentLink(data);
							setLoading(false);
						} else {
							console.error('Could not get payment link')
						}
					})
					.catch((error) => {
						console.log(error);
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
		}, [q]);
		
	function checkPaymentStatus() {
		if(!paymentSuccessful) {
			if(paymentProcessing) {			
				console.log('Checking status of order '+squarePaymentLink.orderId);
				fetch(`${process.env.REACT_APP_NODE_BACKEND}/orderstatus?order=${squarePaymentLink.orderId}`)
				.then((res) => {
					return res.json();
				})
				.then((data) => {			
					console.log(data);
					if(data.state === 'OPEN') {
						setPaymentProcessing(false);
						setPaymentSuccessful(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
			}
		}
	}

	useEffect(() => {
		const onFocus = () => checkPaymentStatus();			
		window.addEventListener('focus', onFocus);		
		return () => {
			window.removeEventListener('focus', onFocus);
		}
	}, [paymentProcessing]);
	
	const handlePaymentProcessingPopup = () => {
		setPaymentProcessing(true);
		window.scrollTo(0, 0);
		document.body.classList.toggle('no-scroll');
	}
	
	return(
		<>
			{paymentProcessing && (
				<div className="payment-status-wrapper">
					Please complete your payment with Square, then return here
				</div>
			)}
			{paymentSuccessful && (
				<div className="payment-status-wrapper success">
					<div className="payment-success">
						<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="32" cy="32.5" r="32" fill="#1CA2B2"/>
						<path d="M18.6666 35.1667L29.3333 45.8333L45.3333 19.1667" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>				
						Payment successful	
					</div>
					<a className="button" href="/">Start learning</a>
				</div>
			)}
			<div className="container no-x-padding">
				<div className="blurb x-padding">
					<h1>Purchase a course</h1>
					<p>Buy a course and get instant access. Pay securely with Square.</p>
				</div>
				<div className="course-info-wrapper">
					<div className="course-cards-carousel">
						<div className="course-cards-wrapper">
							{loading ? (
								<>
									<div className="card course-card skeleton"></div>
									<div className="card course-card skeleton"></div>
								</>
							) : (
								<>
									{courseData.map((thisCourse, i) =>								
										<CourseCard 
											buttonEvent={handlePaymentProcessingPopup} 
											buttonText="Purchase"
											data={thisCourse} 
											link={squarePaymentLink.longUrl} 
											key={i} />
									)}	
								</>
							)}
						</div>
					</div>
					<div className="payment-options">
						<div className="icon-bullet">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.5 8.5V7C5.5 4.53125 7.5 2.5 10 2.5C12.4688 2.5 14.5 4.53125 14.5 7V8.5H15C16.0938 8.5 17 9.40625 17 10.5V16.5C17 17.625 16.0938 18.5 15 18.5H5C3.875 18.5 3 17.625 3 16.5V10.5C3 9.40625 3.875 8.5 5 8.5H5.5ZM7.5 8.5H12.5V7C12.5 5.625 11.375 4.5 10 4.5C8.59375 4.5 7.5 5.625 7.5 7V8.5Z" fill="#1C1E21"/>
							</svg>								
							<strong>Pay securely by</strong>					
						</div>	
						<img src="images/square-logo.svg" alt="Square" />
					</div>
				</div>
			</div>
		</>
	)
	
}
