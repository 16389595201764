import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../contexts';
import { setToken } from '../helpers';

import { Button } from '../components/atoms';

export default function Login() {
	
	const navigate = useNavigate();
	const { setUser } = useAuthContext();
	
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	async function handleLogin(formData) {		
		setLoading(true);
		try {
			const value = {
				identifier: formData.email,
				password: formData.password,
			};
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(value),
			});	
			const data = await response.json();
			if (data?.error) {
				throw data?.error;
			} else {
				console.log(data);
				setToken(data.jwt);
				setUser(data.user);	
				setAuthenticated(true);
				window.location.replace('/dashboard');
			}
		} catch (error) {
			console.error(error);
			if(error.message === 'Invalid identifier or password') {
				setError({ message: 'Incorrect email address or password. 😞 Check you’ve typed them correctly and try again.' });
			} else if(error.message === 'Your account has been blocked by an administrator') {
				setError({ message: 'Your account has been blocked.' });
			} else if(error.message === 'Load failed') {
				setError({ message: 'Unable to connect to authentication server.' });
			} else {
				setError(error);
			}			
		} finally {
			setLoading(false);
		}
	}
	
	return(
		<>
			<div className="container account external">
				<div className="blurb">
					<h1>Log in</h1>
				</div>	
				<div className="card">
					{error && (
						<div className="placard error">						
							{error.message}
						</div>
					)}
					{errors.email?.type === 'required' && (
						<div className="placard error">Please enter your email address ✉️</div>
					)}
					{errors.email?.type === 'pattern' && (
						<div className="placard error">Please enter a valid email address ✉️</div>
					)}
					{errors.password?.type === 'required' && (
						<div className="placard error">Please enter your password 🔐</div>
					)}
					{authenticated && (
						<div className="placard success">Logged in! 😎</div>
					)}
					<div className="centred-content">
						<form onSubmit={handleSubmit(handleLogin)}>
							<div className="rows">							
								<div className="row">
									<label htmlFor="email" className="row-label"><strong>Email address</strong></label>
									<input 
										type="email" 
										id="email" 
										name="email" 
										className="account-form"
										{...register('email', { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/ })}
										aria-invalid={errors.email ? "true" : "false"}
									/>
								</div>
								<div className="row">
									<label htmlFor="password" className="row-label">Password</label>
									<input 
										type="password" 
										id="password" 
										name="password" 
										className="account-form"
										{...register('password', { required: true })}
										aria-invalid={errors.password ? "true" : "false"}
									/>
								</div>
								<div className="row centred">						
									{loading ? (
										<Button	label="Logging in…" disabled={true} />			
									) : (
										<button type="submit">Log in</button>			
									)}							
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)	
	
}
