import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAuthContext } from '../contexts';
import { setToken } from '../helpers';

import { Button } from '../components/atoms';

export default function Signup() {
	
	const { setUser } = useAuthContext();
	
	const [loading, setLoading] = useState(false);
	const [passwordBannerVisible, setPasswordBannerVisible] = useState(false);
	const [error] = useState(null);
	const [authenticated, setAuthenticated] = useState(false);
	
	const prefilledName = localStorage.getItem('signupFirstname');
	const prefilledEmail = localStorage.getItem('signupEmail');
	
	const { register, handleSubmit, setError, setFocus, formState: { errors } } = useForm();
	
	useEffect(() => {
		if(prefilledName && prefilledEmail) {
			document.getElementById('newPassword').focus();
			setPasswordBannerVisible(true);
		} else {
			document.getElementById('firstName').focus();
		}
	}, [prefilledName, prefilledEmail]);
	
	async function handleUserSignup(formData) {
		setLoading(true);
		var trialExpiry = new Date();
		trialExpiry.setDate(trialExpiry.getDate() + 7);
		try {
			const signupData = {
				username: formData.email,
				email: formData.email,
				password: formData.password,
				firstName: formData.firstName.trim(),
				trialExpiry: trialExpiry
			}
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/auth/local/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(signupData),
			});
		
			const data = await response.json();
			if (data?.error) {
				throw data?.error;
			} else {
				localStorage.removeItem('signupFirstname');
			  localStorage.removeItem('signupEmail');
				setToken(data.jwt);		
				setUser(data.user);		
				window.location.replace('/dashboard');
			}
		} catch (error) {
			console.error(error);
			if(error.message === 'Email or Username are already taken') {
				setError('email', { type: 'taken' });
				setFocus('email');
				setPasswordBannerVisible(false);
			} else {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}
	
	return(
		<>
			<div className="container account external">
				<div className="blurb">
					<h1>Sign up</h1>
				</div>	
				<div className="card">
					{error && (
						<div className="placard error">						
							{error.message}
						</div>
					)}
					{errors.firstName?.type === 'required' && (
						<div className="placard error">Please enter your first name 👤</div>
					)}
					{errors.email?.type === 'taken' && (
						<div className="placard error">An account already exists under this email address ✉️</div>
					)}
					{errors.email?.type === 'required' && (
						<div className="placard error">Please enter your email address ✉️</div>
					)}
					{errors.email?.type === 'pattern' && (
						<div className="placard error">Please enter a valid email address ✉️</div>
					)}
					{errors.password?.type === 'required' && (
						<div className="placard error">Please enter your password 🔐</div>
					)}
					{errors.confirmPassword?.type === 'required' && (
						<div className="placard error">Please confirm your password 🔐</div>
					)}
					<div className="centred-content">
						<form onSubmit={handleSubmit(handleUserSignup)}>
							<div className="rows">			
								<div className="row">
									<label htmlFor="firstName" className="row-label"><strong>First name</strong></label>
									<input 
										type="text" 
										id="firstName" 
										name="firstName" 
										className="account-form"
										defaultValue={prefilledName}
										{...register('firstName', { required: true })}
										aria-invalid={errors.firstName ? "true" : "false"}
									/>
								</div>				
								<div className="row">
									<label htmlFor="email" className="row-label"><strong>Email address</strong></label>
									<input 
										type="email" 
										id="email" 
										name="email" 
										className="account-form"
										defaultValue={prefilledEmail}
										{...register('email', { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/ })}
										aria-invalid={errors.email ? "true" : "false"}
									/>
								</div>
							</div>
							{(passwordBannerVisible && 
								<>
									{(prefilledName && prefilledEmail) && (
										<div className="placard info">To finish signing up, please create a password 🔐</div>
									)}
								</>
							)}							
							<div className="rows">
								<div className="row">
									<label htmlFor="password" className="row-label">Password</label>
									<input 
										type="password" 
										id="newPassword" 
										name="newPassword" 
										className="account-form"
										{...register('password', { required: true, minLength: 6 })}
										aria-invalid={errors.password ? "true" : "false"}
									/>
								</div>
								<div className="row">
									<label htmlFor="password" className="row-label">Confirm password</label>
									<input 
										type="password" 
										id="confirmPassword" 
										name="confirmPassword" 
										className="account-form"
										{...register('confirmPassword', { required: true, minLength: 6 })}
										aria-invalid={errors.confirmPassword ? "true" : "false"}
									/>
								</div>
								<div className="row centred">						
									{loading ? (
										<Button	label="Signing up…" disabled={true} />			
									) : (
										<button type="submit">Sign up</button>			
									)}							
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)	
	
}
