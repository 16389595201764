export default function CourseCard({
		data,
		disabled,
		buttonEvent, 
		buttonText,
		link, 
		paymentProcessing
	}) {
		
	if(!buttonText) {
		buttonText = "Sign up and purchase";
	}
		
	if(!data.attributes.publishedAt) {
		disabled = "disabled"
		buttonText = "Coming soon"
	}
	
	var buttonDisabled = null;
	
	if(paymentProcessing || disabled) {
		buttonDisabled = "disabled";
	}
	
	const handlePurchaseButtonClick = () => {
		if(link) {
			window.open(link)
		}		
		if(buttonEvent) (
			buttonEvent()
		)		
	}
	
	return(
		<div className={`card course-card${disabled ? " disabled" : ""}`}>
			<div className="course-info">
				<div className="course-blurb">
					<h3>{data.attributes.name}</h3>
					<p>{data.attributes.cardTagline}</p>
				</div>
				<div className="course-details">
					<h4>What you’ll learn</h4>
					<ul>
						{data.attributes.features.map((thisFeature, i) =>								
							<li key={i}>
								<svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 11L9 15L15 5" stroke="#1C1E21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
								{thisFeature.feature}
							</li>
						)}		
					</ul>					
				</div>
			</div>
			<div className="course-price-cta">
				<div className="course-details">
					<h4>Details</h4>
					<ul>
						<li>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.75 4.5H12.25V3.25C12.25 2.84375 12.5625 2.5 13 2.5C13.4062 2.5 13.75 2.84375 13.75 3.25V4.5H15C16.0938 4.5 17 5.40625 17 6.5V16.5C17 17.625 16.0938 18.5 15 18.5H5C3.875 18.5 3 17.625 3 16.5V6.5C3 5.40625 3.875 4.5 5 4.5H6.25V3.25C6.25 2.84375 6.5625 2.5 7 2.5C7.40625 2.5 7.75 2.84375 7.75 3.25V4.5ZM4.5 16.5C4.5 16.7812 4.71875 17 5 17H15C15.25 17 15.5 16.7812 15.5 16.5V8.5H4.5V16.5Z" fill="#1C1E21"/>
							</svg>							
							<span><strong>Recommended time</strong><br />{data.attributes.duration} weeks</span>
						</li>
						<li>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.6562 3.28125C15.5312 2.40625 16.9375 2.40625 17.8125 3.28125L18.2188 3.6875C19.0938 4.5625 19.0938 5.96875 18.2188 6.84375L12.0312 13.0312C11.7812 13.2812 11.4375 13.5 11.0625 13.5938L7.9375 14.5C7.6875 14.5625 7.40625 14.5 7.21875 14.2812C7 14.0938 6.9375 13.8125 7 13.5625L7.90625 10.4375C8 10.0625 8.21875 9.71875 8.46875 9.46875L14.6562 3.28125ZM16.75 4.34375C16.4688 4.0625 16 4.0625 15.7188 4.34375L14.7812 5.25L16.25 6.71875L17.1562 5.78125C17.4375 5.5 17.4375 5.03125 17.1562 4.75L16.75 4.34375ZM9.34375 10.8438L8.8125 12.6875L10.6562 12.1562C10.7812 12.125 10.875 12.0625 10.9688 11.9688L15.1875 7.75L13.75 6.3125L9.53125 10.5312C9.4375 10.625 9.375 10.7188 9.34375 10.8438ZM9.25 4.5C9.65625 4.5 10 4.84375 10 5.25C10 5.6875 9.65625 6 9.25 6H5.75C5.03125 6 4.5 6.5625 4.5 7.25V15.75C4.5 16.4688 5.03125 17 5.75 17H14.25C14.9375 17 15.5 16.4688 15.5 15.75V12.25C15.5 11.8438 15.8125 11.5 16.25 11.5C16.6562 11.5 17 11.8438 17 12.25V15.75C17 17.2812 15.75 18.5 14.25 18.5H5.75C4.21875 18.5 3 17.2812 3 15.75V7.25C3 5.75 4.21875 4.5 5.75 4.5H9.25Z" fill="#1C1E21"/>
							</svg>
							<span><strong>Assessment</strong><br />{data.attributes.assessmentType}</span>
						</li>
					</ul>
				</div>
				<div className="course-pricing">
					<div className="price-detail">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.25 7C4.64844 7 5 7.35156 5 7.75V12.9531C5 13.3516 4.64844 13.6797 4.25 13.6797H2.75C2.32812 13.6797 2 13.3516 2 12.9531V7.72656C2 7.32812 2.32812 7 2.75 7H4.25ZM14 7.82031C14 8.38281 13.6953 8.85156 13.2734 9.13281C13.2969 9.27344 13.3203 9.39062 13.3203 9.50781C13.3203 10.0469 13.0391 10.5156 12.6406 10.7969C12.6406 10.8672 12.6406 10.9375 12.6406 11.0078C12.6406 11.5469 12.3828 12.0156 11.9609 12.2969C11.9141 13.1172 11.2344 13.75 10.4141 13.75H9.17188C8.32812 13.75 7.50781 13.4922 6.82812 12.9766L5.96094 12.3438C5.82031 12.2266 5.72656 12.0625 5.72656 11.875C5.72656 11.5938 5.98438 11.3125 6.3125 11.3125C6.42969 11.3125 6.54688 11.3594 6.64062 11.4297L7.50781 12.0859C7.97656 12.4375 8.5625 12.6484 9.17188 12.6484H10.4141C10.6484 12.6484 10.8594 12.4375 10.8594 12.2031C10.8594 12.1094 10.8125 12.0859 10.8125 11.9688C10.8125 11.3359 11.5156 11.5469 11.5156 11.0078C11.5156 10.7969 11.375 10.75 11.375 10.4922C11.375 9.78906 12.1953 10.0938 12.1953 9.50781C12.1953 9.20312 11.9141 9.20312 11.9141 8.82812C11.9141 8.54688 12.1484 8.28906 12.4531 8.26562C12.6875 8.26562 12.875 8.05469 12.875 7.82031C12.875 7.58594 12.6641 7.39844 12.4062 7.375H9.19531C8.89062 7.375 8.63281 7.16406 8.63281 6.83594C8.63281 6.74219 8.65625 6.64844 8.70312 6.55469C9.125 5.80469 9.3125 5.05469 9.3125 4.86719C9.3125 4.67969 9.17188 4.39844 8.77344 4.39844C8 4.39844 8.44531 5.82812 6.64062 7.25781C6.54688 7.35156 6.42969 7.375 6.3125 7.375C5.98438 7.375 5.75 7.11719 5.75 6.8125C5.75 6.17969 6.40625 6.53125 7.15625 4.63281C7.39062 4.02344 7.69531 3.27344 8.77344 3.27344C9.80469 3.27344 10.4375 4.07031 10.4375 4.86719C10.4375 5.17188 10.2969 5.6875 10.0859 6.27344H12.4297C13.2734 6.27344 14 6.97656 14 7.82031Z" fill="#1C1E21"/>
						</svg>
						Unlimited lifetime access
					</div>
					<p className="price">£{data.attributes.price}</p>
				</div>
				<button disabled={buttonDisabled} onClick={handlePurchaseButtonClick}>{buttonText}</button>
			</div>			
		</div>
	)
	
}