import { useState, useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { stringify } from 'qs';

import { useAuthContext } from '../contexts';
import { getToken } from '../helpers';

import { BreadcrumbsContext } from '../contexts';
import { Button } from '../components/atoms';
import { RichTextBlockRenderer } from '../components/renderers';

export default function Course() {
	
	const user = useAuthContext();
	const authToken = getToken();
	
	const { t, i18n } = useTranslation();
	const breadcrumbs = useContext(BreadcrumbsContext);
	const courseSlug = useParams().courseSlug;
	
	const [ course, setCourse ] = useState({
		data: null,
		error: false,
		loading: true,
	});
	
	useEffect(() => {			
		document.body.classList.remove('conversation');
	}, [course]);
	
	var q = stringify({
		sort: ['slug:asc'],
		filters: {
			slug: {
				$eq: courseSlug,
			},
		},
		populate: {
			lessons: {
				populate: ['icon', 'lessonPages'],
			},
		},
		publicationState: 'live',
	});
	
	useEffect(() => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/courses?${q}`, {
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			console.log(data.data[0]);
			if(data.data.length>0) {
				setCourse({
					data: data.data[0],
					loading: false 
				});
				var newBreadcrumbs = [
					{
						path: '/dashboard',
						title: 'Icelandic with Max',
						mobileStyle: 'compact'
					}, 
					{	path: "/"+data.data[0].attributes.slug+"/", 
						title: data.data[0].attributes.name	
					}
				];
				breadcrumbs.setBreadcrumbs(newBreadcrumbs);
			} else {
				setCourse({
					data: null,
					loading: false,
					error: true
				});
			}
		})
		.catch((error) => {
			setCourse({
				data: null,
				loading: false,
				error: error
				});
			console.log(error);
		});
	}, [q]);
	
	if(course.loading) {
		return (
			<>			
				<div className="container course">
					<div className="prose">
						<h1 className="skeleton">Course name</h1>
					</div>
					<p className="skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ante sit amet fermentum facilisis.</p>
					<section className="lessons">
						<div className="lesson-card skeleton"></div>
						<div className="lesson-card skeleton"></div>
						<div className="lesson-card skeleton"></div>
						<div className="lesson-card skeleton"></div>
						<div className="lesson-card skeleton"></div>
						<div className="lesson-card skeleton"></div>
					</section>
				</div>
			</>
		);
	}
	
	if(course.error) {
		return (
			<>			
				<div className="container error">
					<div className="prose">			
						<h1 className="skeleton">Course name</h1>
					</div>
					<div className="error-placard">This course could not be loaded.</div>
				</div>
			</>
		);
	}
	
	if(course.data) {		
		console.log(course.data.attributes);
		return (
			<>
				<div className="container course">
					<div className="prose">
						<h1>{course.data.attributes.name}</h1>
					</div>
					<RichTextBlockRenderer content={course.data.attributes.intro} />
					<section className="lessons">
					{course.data.attributes.lessons.data.map((thisLesson, i) =>
						<div className="lesson-card" key={i}>
							<div className="lesson-icon-wrapper">
							{thisLesson.attributes.icon.data && (
								<img 
									src={thisLesson.attributes.icon.data.attributes.url}
									alt={thisLesson.attributes.icon.data.attributes.alternativeText}
									className="icon large"
								/>
							)}							
							</div>
							<div className="lesson-label">
								<div>
									<p>{thisLesson.attributes.label}</p>	
									<h2>{thisLesson.attributes.title}</h2>	
								</div>				
								{thisLesson.attributes.lessonPages.data[0] ? (											
									<Link to={`${thisLesson.attributes.slug}/${thisLesson.attributes.lessonPages.data[0].attributes.slug}`}>
										<Button 
											label={t('course.buttonStartLesson')}
											icon="next"
											iconPlacement="after"
										/>
									</Link>
								) : (
									<Button 
										label={t('course.buttonStartLesson')}
										icon="next"
										iconPlacement="after"
										disabled="disabled"
									/>
								)}	
							</div>
						</div>
					)}
					</section>
				</div>
			</>
		);
	}
}